import "./InstagramFeed.css";
import TextBlock from "../TextBlock/TextBlock";
import {useEffect, useState, useRef, useCallback} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const InstagramFeed = () => {
    const [posts, setPosts] = useState([]);
    const swiperRef = useRef(null);

    const AutoHeight = () => {
        setTimeout(() => {
            swiperRef.current.style.height = swiperRef.current.querySelector('.swiper-slide-active .instagram-post').scrollHeight + 'px';
        }, 10);
    };

    useEffect(() => {
        const fetchInstagramPosts = async () => {
            const response = await fetch("igfeed.php");
            const data = await response.json();

            setPosts(data.error ? [] : data.data);
        };

        fetchInstagramPosts();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', AutoHeight);
    }, []);


    return (
        <div id="novedades" className="instagram-feed">
            <TextBlock
                title={
                    <>
                        ¡Síguenos en{" "}
                        <a className="ig" href="https://www.instagram.com/snacksunbar/" target="_blank" rel="noreferrer">
                            Instagram
                        </a>{" "}
                        y{" "}
                        <a className="fb" href="https://www.facebook.com/profile.php?id=61555486260674" target="_blank" rel="noreferrer">
                            Facebook
                        </a>{" "}
                        para estar al día de todos nuestros eventos!
                    </>
                }
                content={
                    posts.length > 0 && (
                        <div className="instagram-feed-content">
                            <Swiper
                                ref={swiperRef}
                                modules={[Navigation, Pagination]}
                                navigation
                                pagination={{clickable: true}}
                                spaceBetween={10}
                                slidesPerView={1}
                                onLoad={AutoHeight}
                                onSlideChange={AutoHeight}
                            >
                                {posts.map((post) => (
                                    <SwiperSlide key={post.id} className="instagram-slide">
                                        <a href={post.permalink} target="_blank" rel="noreferrer">
                                            <div className="instagram-post">
                                                <div className="post-media">
                                                    {post.media_type === "IMAGE" || post.media_type === "CAROUSEL_ALBUM" ? (
                                                        <img
                                                            src={post.media_url}
                                                            alt={post.caption || "Instagram post"}
                                                        />
                                                    ) : post.media_type === "VIDEO" ? (
                                                        <video
                                                            src={post.media_url}
                                                            controls
                                                        />
                                                    ) : null}
                                                </div>
                                                <div className="post-caption">
                                                    <p>
                                                        <strong>snacksunbar</strong> {post.caption || ""}
                                                    </p>
                                                </div>
                                                <div className="post-footer">
                                                    <span>❤️ {post.like_count || 0} Me gusta</span>
                                                    <span>🗨️ {post.comments_count || 0} comentario</span>
                                                </div>
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    )
                }
            />
        </div>
    );
};

export default InstagramFeed;
